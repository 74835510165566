<template>
    <div class="login-form">
      <h2>Login</h2>
      <form>
        <div class="form-group">
          <label for="username">Username</label>
          <input type="text" id="username" v-model="username" required />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" id="password" v-model="password" required />
        </div>
        <button type="submit" @click="login()">Login</button>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        username: "",
        password: "",
      };
    },
    methods: {
      login() {
        // var md5 = require('md5');

        //  this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/user', { email: this.username, password: md5(this.password)},{
        //      headers: {
        //          'Content-Type': 'application/json',
        //         //  'Cross-Origin-Read-Blocking': '*'
        //      },            
        //  }).then((response) => {
        //     if(response.data.length > 0 ){
        //       localStorage.logado = response.data['pass'];
        //     }
        //  },)
      },
    },
    mounted(){
      if(localStorage.logado){this.$router.push('/draw-list')}
    }
  };
  </script>
  
  <style>
  .login-form {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 10%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="password"] {
    width: 80%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button[type="submit"] {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #3e8e41;
  }
  </style>