<template>
    <div style="background-color: white; opacity: 0.9;">
        <h2>Lista de desenhos</h2>
        <div class="container">
            <div class="row">
                <div class="col-sm-1"><h5 class="new float-left"><router-link to="/new-draw"><button type="button" class="btn btn-lg btn-primary" >Novo Desenho</button></router-link></h5></div>
                <div class="col-sm-1"></div>
                <div class="col-sm-3"><h5 class="new float-left"><button type="button" class="btn btn-lg btn-danger"  @click="deleteDraw()">Deletar Desenhos selecionados</button></h5></div>
                <div class="col-sm-6"></div>
                <div class="col-sm-1"><h5 class="new float-left"><router-link to="/get-tag"><button type="button" class="btn btn-lg btn-primary" >Lista de categorias</button></router-link></h5></div>
            </div>
        </div>
        
        <table  class="table">
            <thead>rout
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">#</th>
                    <th scope="col">Data</th>
                    <th scope="col">Titulo</th>
                    <th scope="col">Descrição</th>
                    <th scope="col">Imagem</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items" :key="item.id">
                    <th scope="row"> <input class="form-check-input" type="checkbox" value=""  v-bind:id="item.id" @click="selectRow(item.id)"></th>       
                    <th scope="row"><router-link :to="{ name: 'edit-draw', params: { id: item.id}}" class="edit">Editar</router-link></th>                        
                    
                    <th scope="row">{{ item.date }}</th>
                    <td> {{item.title}} </td>
                    <td> {{item.description}} </td>
                    <td> {{item.file}} </td>
                </tr>
            </tbody>
        </table>
      <!-- <ul>
        <b-table striped hover :items="item"></b-table>
        <li v-for="(item, index) in items" :key="index">
            {{ format_date( item.created_at) }}  {{ item.title }} {{ item.description }} {{ item.file }}
        </li>
      </ul> -->
    </div>
  </template>
  
  <script>
    import moment from 'moment'

    export default {
        data() {
            return {
                selectedIds:[],
                items: []
            };
        },
        components: {
        },
        async mounted(){
            this.callApi()
        },
        methods: { 
            callApi(){
                this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/getDrawList',{
                headers: {
                    'Content-Type': 'application/json',
                    //  'Cross-Origin-Read-Blocking': '*'
                },            
            }).then((response) => {
                if(response.status == 200 && response.data.length > 0){
                    response.data.forEach(element => {
                        var data = {}
                        data.date = this.format_date(element.created_at)
                        data.description = element.description
                        data.title = element.title
                        data.file = element.file
                        data.id = element.id
                        this.items.push(data)
                    });
                }
            },)
            },
            selectRow(id){
                this.selectedIds.push(id);
                console.log(this.selectedIds)
            },
            deleteDraw(){                
                console.log({id:  this.selectedIds.join(',')})
                this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/diasbleDraw',{id:  this.selectedIds.join(',')},{
                    headers: {
                        'Content-Type': 'application/json',
                        //  'Cross-Origin-Read-Blocking': '*'
                    },            
                }).then((response) => {
                    if(response.status == 201){
                        this.$router.push('/draw-list')
                    }
                },)
            },
            format_date(value){
                if (value) {
                return moment(String(value)).format('DD/MM/YYYY')
                }
            },
        },
    };
  </script>
  
  <style>
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  </style>