<template>
    <div class="home background ">    
        <div class="panel blur">
            <div class="col-sm-3 historias" stlye=''>
                <div v-for="item in items" :key="item.id">
                    <label class="main-text" @click="toggle(item)" >{{ item['titulo'] }}</label><br/>
                 </div>
            </div>    
            <div id='painel-stories' style='background-color: white; '>
                <div v-if="stories.length > 0">
                  <label  class="main-text">{{ stories[0]['title'] }}</label>
                  <div v-for="item in stories" :key="item.id" v-bind:id="item.id" >
                    <div class="row" style="margin:20px; ">
                        <div class='row'>
                            <div class='col-sm-5 main-text' style="margin:auto">
                                {{ item['description'] }}
                            </div>
                        </div>                        
                        <img class="col-sm-7 img-d" style="margin:auto"
                                        :src="item['file']" alt="alternatetext">
                        <!-- <div class='row' >
                            <div class='col-sm-7 main-text' style="margin:auto">
                                {{ item['description'] }}
                            </div>
                        </div>
                      <img class="col-sm-5 img-e" style="margin:auto"
                                  :src="item['file']" alt="alternatetext"> -->
                    </div>
                  </div>
                </div>
            </div>
            <!-- <div >
                <div v-for="item in items" :key="item.id" v-bind:id="item.id" >
                    <div v-if="item.active">
                        <div class="row" style="margin:20px">
                            <div class='row' v-if="item['id'] % 2 == 0">
                                <div class='col-sm-5 main-text' style="margin:auto">
                                    {{ item['description'] }}
                                </div>
                                <img class="col-sm-7 img-d"
                                            :src="item['file']" alt="alternatetext">
                            </div>
                            <div class='row' v-else>
                                <img class="col-sm-5 img-e"
                                            :src="item['file']" alt="alternatetext">
                                <div class='col-sm-7 main-text' style="margin:auto">
                                    {{ item['description'] }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
  </template>
  
  <script>
    export default {
      data() {
        return {
          isActiveMerece: false,
          adocelencia: false,
          entrevista: false,
          dispensa: false,
          items: [],
          stories: []
        };
      },   
        async mounted(){
            console.log('1');
            this.callApi()
        },
      methods: {
            callApi(){
                    this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/getStories',{
                    headers: {
                        'Content-Type': 'application/json',
                        //  'Cross-Origin-Read-Blocking': '*'
                    },
                }).then((response) => {
                    if(response.status == 200 && response.data.length > 0){
                      response.data.forEach(element => {
                          this.items.push(element);
                      })
                    }
                },)
            },
            toggle(i) {
              this.stories = [] 
              this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/getDetailedStories',
                { id_stories: i.id },
                {
                  headers: {
                    'Content-Type': 'application/json',
                      //  'Cross-Origin-Read-Blocking': '*'
                    },
                }).then((response) => {
                    if(response.status == 200 && response.data.length > 0){
                      response.data.forEach(element => {
                        this.stories.push(element);
                      })
                    }
                },)

        },
      },
      name: 'Home-',
    }
  </script>
  <style>
  .hidden{
      display: none;
  }
    .historias{
      text-align:left; 
      margin-left: 20px; 
      margin-top:20px;
    }
  .img{
    height: 5%;
  }
  .titulo-principal{
      color: #000;
      font-size: 10vh; 
      font-weight: 700;
    }
  
    .main-text{
      color: #000;
      font-size: 24px;
      font-weight: 700;
      margin: auto;
    }
    .bakcground-img{    
        filter: blur(5px);
    }
    .home{
      width: 100%;
    }
    .panel{
        overflow-y: scroll;
        z-index: 999999;
        height: 80vh;
        max-height: 100vh;
        width: 100vw;
    }
    .hidden{
        display: none;
    }
    .titulo{
        font-size: 30px;
        color: black;
        font-weight: 700;
    }
    .descricao{
        font-size: 25px;
        color: black;
        font-weight: 500;
    }
    .panel-draw{
        /* opacity: 0.9; */
        /* background-color: gray; */
        height: 80vh;
        width: 95vw;
  
        position: absolute;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;    
        margin: auto;
    }
    .img{
        max-height: 80vh;
        max-width: 90vw;
    }
    .blur {
        background: rgba(255, 255, 255, 0.2); 
        backdrop-filter: blur(8px); 
        height: 100vh;
        width: 100%;
    }
    .form-edit{
        
        margin: auto;
        width: 50%;
        padding: 10px;
    }
    
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;   
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  </style>