import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import LoginView from '../views/LoginView.vue'
import DrawListView from '../views/DrawListView.vue'
import NewDrawView from '../views/NewDrawView.vue'
import TagList from '../views/TagListView.vue'
import NewTag from '../views/NewTagView.vue'
import explore from '../views/TagsView.vue'
import draw from '../views/DrawView.vue'
import editDraw from '../views/EditDrawView.vue'
import about from '../views/AboutView.vue'
import contact from '../views/ContactVue.vue'
import stories from '../views/StoriesVueView.vue'
import privacy from '../views/PrivacyPolicy.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    title:'Home',
    component: HomeView,
    meta: {
      title: 'Welcome to the dashboard'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/draw-list',
    name: 'draw-list',
    component: DrawListView
  },
  {
    path: '/new-draw',
    name: 'new-draw',
    component: NewDrawView
  },
  {
    path: '/get-tag',
    name: 'get-tag',
    component: TagList
  },
  {
    path: '/new-tag',
    name: 'new-tag',
    component: NewTag
  },
  {
    path: '/explore',
    name: 'explore',
    component: explore
  },
  {
    path: '/draw/:id',
    name: 'draw',
    props: { default: true, sidebar: false },
    component: draw,
    
  },
  {
    path: '/edit-draw/:id',
    name: 'edit-draw',
    component: editDraw
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  },
  {
    path: '/stories',
    name: 'stories',
    component: stories
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
