<template>
    <div class="home background ">    
        <div class="panel blur">
            <ul id="nav">

                <li class=""><router-link to="/" >
                  <label class="web" style="text-decoration:none !important;">Inicio</label>
                  <font-awesome-icon class="phone"  icon="home" /></router-link>                
                </li>

                <li class="twitter" v-on:click="setClass()"><label>Charges</label>
                    <ul v-bind:class="getClass()">
                        <li>
                          <router-link
                              :to="{
                                name: 'draw',
                                params: {
                                  id: '1'
                                }
                              }">
                              <label> Alienígenas do Passado</label>
                          </router-link>
                        </li>
                        <li>
                          <router-link
                              :to="{
                                name: 'draw',
                                params: {
                                  id: '2'
                                }
                              }">
                              <label> Oak Island</label>
                          </router-link></li>
                        <li>
                          <router-link
                              :to="{
                                name: 'draw',
                                params: {
                                  id: '3'
                                }
                              }">
                              <label> Histórias da fazenda</label>
                          </router-link></li>
                        <li>
                          <router-link
                              :to="{
                                name: 'draw',
                                params: {
                                  id: '4'
                                }
                              }">
                              <label> Histórias do trabalho</label>
                          </router-link></li>
                        <li>
                          <router-link
                              :to="{
                                name: 'draw',
                                params: {
                                    id: '5'
                                }
                              }">
                              <label> Diversos</label>
                          </router-link> </li>
                        <li>
                          <router-link
                              :to="{
                                name: 'draw',
                                params: {
                                    id: '6'
                                }
                              }">
                              <label> Celebridades</label>
                          </router-link></li>
                    </ul>
                </li>
                    <li  ><router-link to="/about" style="text-decoration:none !important;" > 
                        <label class="web" style="text-decoration:none !important;">Como nasceu Merece1Desenho</label>
                        <font-awesome-icon class="phone" icon="head-side-virus" />
                    </router-link></li>
                    <li  class=""><router-link to="/contact" style="text-decoration:none !important;"> 
                        <label class="web" style="text-decoration:none !important;">Fale conosco</label>
                        <font-awesome-icon class="phone" icon="phone" /></router-link>
                    </li>
            </ul>
          <label class='titulo-principal'>Fale Conosco</label>
          <div class="painel-info">
            <label class="info">Me chamo Diego. Faço Caricaturas, charges e desenhos em geral. Também faço desenhos de pets. Realizo a arte à lapis preto e branco, à caneta, lápis de cor e digital.</label><br>
            <label class="img-whats"> 
              <a href='https://web.whatsapp.com/send/?phone=5511952983753&text=Ola+Tenho+interesse+em+comprar+seus+serviços&type=phone_number&app_absent=0' target="_blank">                 
                <img class=' img-whats' src='../assets/WhatsApp-icone-3.png' alt="whats">
                <label class="info-zap"> Entre em contato pelo WhatsApp</label>
              </a>  
            </label>
          </div>
        </div>
    </div>
  </template>
  

  <script>
    export default {
      data() {
        return {
            active: false,
        };
      },    
      methods: {
        setClass(){
            this.active = !this.active
            console.log(this.active)
        },
        getClass(){
            if(!this.active){
                return {
                    'displayN': 'none'
                }
            }else{
                return {
                    '': ''
                }
            }
        },
      },
      name: 'Home-',
    }
  </script>
  <style>
  .img-whats{
    width: 20%;
  }
  .info-zap{
      /* color: #000; */
      font-size: 2vh; 
      font-weight: 700;

  }
    .info{
      color: #000;
      font-size: 3vh; 
      font-weight: 700;
      
    }
    .sub-title{
      color: #000;
      font-size: 5vh; 
      font-weight: 700;

    }
    .painel-info{
      text-align: left;
      margin: 20px;
    }
  .hidden{
      display: none;
  }
    .historias{
      text-align:left; 
      margin-left: 20px; 
      margin-top:20px;
    }
  .img{
    height: 5%;
  }
  .titulo-principal{
      color: #000;
      font-size: 10vh; 
      font-weight: 700;
    }
  
    .bakcground-img{    
        filter: blur(5px);
    }
    .home{
      width: 100%;
    }
    .panel{
        overflow-y: scroll;
        z-index: 999999;
        height: 80vh;
        max-height: 100vh;
        width: 100vw;
    }
    .hidden{
        display: none;
    }
    .panel-draw{
        /* opacity: 0.9; */
        /* background-color: gray; */
        height: 80vh;
        width: 95vw;
  
        position: absolute;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;    
        margin: auto;
    }
    .img{
        max-height: 80vh;
        max-width: 90vw;
    }
    .blur {
        background: rgba(255, 255, 255, 0.2); 
        backdrop-filter: blur(8px); 
        height: 100vh;
        width: 100%;
    }
    .form-edit{
        
        margin: auto;
        width: 50%;
        padding: 10px;
    }
    
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;   
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  </style>