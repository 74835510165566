<template>
    <div class="home background ">    
        <div class="center-pannel">
            <h1>Política de Privacidade</h1>

            <label>No Merece1Desenho, privacidade e segurança são prioridades e nos comprometemos com a transparência do tratamento de dados pessoais dos nossos usuários/clientes. Por isso, esta presente Política de Privacidade estabelece como é feita a coleta, uso e transferência de informações de clientes ou outras pessoas que acessam ou usam nosso site.</label>

            <label>Ao utilizar nossos serviços, você entende que coletaremos e usaremos suas informações pessoais nas formas descritas nesta Política, sob as normas da Constituição Federal de 1988 (art. 5º, LXXIX; e o art. 22º, XXX – incluídos pela EC 115/2022), das normas de Proteção de Dados (LGPD, Lei Federal 13.709/2018), das disposições consumeristas da Lei Federal 8078/1990 e as demais normas do ordenamento jurídico brasileiro aplicáveis.</label>

            <label>Dessa forma, Merece1Desenho, doravante denominada simplesmente como Merece1Desenho no papel de Controladora de Dados, obriga-se ao disposto na presente Política de Privacidade.</label>

            <label>1. Quais dados coletamos sobre você e para qual finalidade?</label>
            <label>Não é coletado nenhum dado pessoal.</label>
            <label>1.1. Dados pessoais fornecidos pelo titular</label>
            <label>Não é fornecido nenhum dado.</label>
            <label>1.2. Dados pessoais coletados automaticamente</label>
            <label>Não é coletado nenhum automaticamente.</label>
            <label>2. Como coletamos os seus dados?</label>
            <label>Não é fornecido nenhum dado.</label>
            <label>2.1. Consentimento</label>
            <label>É a partir do seu consentimento que tratamos os seus dados pessoais. O consentimento é a manifestação livre, informada e inequívoca pela qual você autoriza a Merece1Desenho a tratar seus dados.</label>
            <label>Assim, em consonância com a Lei Geral de Proteção de Dados, seus dados só serão coletados, tratados e armazenados mediante prévio e expresso consentimento.</label>
            <label>O seu consentimento será obtido de forma específica para cada finalidade acima descrita, evidenciando o compromisso de transparência e boa-fé da (nome empresarial simplificado) para com seus usuários/clientes, seguindo as regulações legislativas pertinentes.</label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>
            <label></label>

            

        <div>
        </div>
        </div>

    </div>
  </template>
  
  <script>  
  </script>
  <style>

  .center-pannel{
    background-color: #FFF;
  }
@media screen and (max-width: 600px) {
  .web{
    display: none !important;
  }
  #nav{
    align-items: baseline;
  }
#nav ul li {
  
  background: #333;
  /* width: 50px; */
  justify-content: space-around;
  padding-top: 10%;
  height: 50px;
}

}
@media screen and (min-width: 601px) {
  .phone{
    margin: auto;
  }
}
    .none{

    }
    .bakcground-img{    
        filter: blur(5px);
    }
    .home{
      width: 100%;
    }
    .panel{
        height: 80vh;
        width: 100vw;
    }
    .hidden{
    }
    .titulo-a{
        /* font-size: 7vh; */
        color: black;
        font-weight: 700;
    }
    .descricao-a{
        font-size: 4vh;
        color: black;
        font-weight: 500;
    }
    .panel-draw{
        width: 95vw;
        position: absolute;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;    
        margin: auto;
    }
    .img{
        max-height: 80vh;
        max-width: 90vw;
    }
    .blur {
        background: rgba(255, 255, 255, 0.2); 
        backdrop-filter: blur(8px); 
        height: 100vh;
        width: 100%;
    }
    .volt{
        padding: 10px;
        width: 10%;
    }
    .volt-tit{
        font-size: 2vh;
        text-decoration: none;
        text-align: start;
        color: #000;
        font-weight: 500;
    }
    /* menu  */
    

    #nav {
        position: sticky;
        z-index: 1;
        flex-wrap: nowrap;
        justify-content: space-around;
        height: 50px;
        background: #333;
        /* min-width: 500px; */
        margin-left: 0px;
        padding-left: 0px;
    }

    #nav li {
    text-decoration: none;
      list-style: none;
      float: left;
      height: 40px;
    }

    #nav li label {
    text-decoration: none;
      padding: 0px 10px 0px 30px;
      margin: 0px 0;
      line-height: 40px;
      text-decoration: none;
      height: 50px;
      color: #FFF;
    }

    #nav ul {
      background: #f2f5f6;
      padding: 0px;
    }

    #nav .site-name,
    #nav .site-name:hover {
      padding-left: 10px;
      padding-right: 10px;
      color: #FFF;
      width: 160px;
    }
    #nav ul li {
      background: #333;
      width: 300px;
      text-align: left;

      height: 50px;
    }

    #nav ul li:hover {
      background: #343a40;
    }
    #a{
        text-decoration:none !important;
    }
    .displayN{
    }
    .twitter{
        width: 200px;
    }
  </style>
  