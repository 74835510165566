<template>
  <div class="home background ">    
      <div class="panel blur"><ul id="nav">

                <li class=""><router-link to="/" >
                        <label class="web" style="text-decoration:none !important;">Inicio</label>
                        <font-awesome-icon class="phone"  icon="home" /></router-link>
                </li>
                <li class="twitter" v-on:click="setClass()"><label>Charges</label>
                    <ul v-bind:class="getClass()">
                        <li>
                          <router-link
                              :to="{
                                name: 'draw',
                                params: {
                                  id: '1'
                                }
                              }">
                              <label> Alienígenas do Passado</label>
                          </router-link>
                        </li>
                        <li>
                          <router-link
                              :to="{
                                name: 'draw',
                                params: {
                                  id: '2'
                                }
                              }">
                              <label> Oak Island</label>
                          </router-link></li>
                        <li>
                          <router-link
                              :to="{
                                name: 'draw',
                                params: {
                                  id: '3'
                                }
                              }">
                              <label> Histórias da fazenda</label>
                          </router-link></li>
                        <li>
                          <router-link
                              :to="{
                                name: 'draw',
                                params: {
                                  id: '4'
                                }
                              }">
                              <label> Histórias do trabalho</label>
                          </router-link></li>
                        <li>
                          <router-link
                              :to="{
                                name: 'draw',
                                params: {
                                    id: '5'
                                }
                              }">
                              <label> Diversos</label>
                          </router-link> </li>
                        <li>
                          <router-link
                              :to="{
                                name: 'draw',
                                params: {
                                    id: '6'
                                }
                              }">
                              <label> Celebridades</label>
                          </router-link></li>
                        <li>
                          <router-link
                              :to="{
                                name: 'draw',
                                params: {
                                    id: '7'
                                }
                              }">
                              <label> Pets</label>
                          </router-link></li>
                    </ul>
                </li>
                    <li  ><router-link to="/about" style="text-decoration:none !important;" > 
                        <label class="web" style="text-decoration:none !important;">Como nasceu Merece1Desenho</label>
                        <font-awesome-icon class="phone" icon="head-side-virus" />
                    </router-link></li>
                    <li  class=""><router-link to="/contact" style="text-decoration:none !important;"> 
                        <label class="web" style="text-decoration:none !important;">Fale conosco</label>
                        <font-awesome-icon class="phone" icon="phone" /></router-link>
                    </li>
            </ul>

        <div id='mereceumdesenho'>
          <Label class='titulo-principal'>Como nasceu o Merece1Desenho</label>          
            
          <img class='col-sm-4 img' src='../assets/cegonha.png' alt="cegonha">
          <div class="row" style="margin:20px">
            <div class='col-sm-8 main-text' style="margin:auto">
              "Merece um desenho" era um termo usado pelos meus amigos quando algo engraçado ocorria no ambiente do trabalho. "Diegão, isso merece um desenho..." não demorava muito, e o fato ocorrido já era passado para o papel.
              Do fruto da minha imaginação, uns dotes a mais eram acrescentados, muitas das vezes com fundo de verdade e retratados com sátiras ou ironias. mas no sentido de se manter engraçado. sem ofensas, é claro.
              Vejo o mundo desta maneira, por mais sério que tentarmos nos comportar... algo engraçado sempre aparece para nos descontrair... É da natureza do ser humano esconder algo que o incomoda, mas no final sempre conseguimos superar. 
              Dar risadas é um momento de reflexão, sintonia com o bem-estar de todos.</div>
          </div>
          <img class='col-sm-4 img'  src='../assets/pai.png' alt="pai">
          <div class="row" style="margin:40px">
            <div class='col-sm-8 main-text' style="margin:auto">
                A Origem dos Desenhos  <br/>
                Certo dia meu pai ao voltar do trabalho, deparou-se com um desenho sombrio que estava fazendo. Nem tinha percebido quando ele chegou... 
                Mas lembro que estava fazendo um monstro para assustar meu irmão. Fazia uns desenhos bizarros e colocava pendurado na geladeira com um imã. 
                Meu Pai ficava puto porque isso levava meu irmão dormir com meus pais.
            </div>
          </div>
          
          <img class='col-sm-4 img' src='../assets/pai_doutor.png' alt="doutor">
          <div class="row" style="margin:40px">
            <div class='col-sm-8 main-text' style="margin:auto">
              Meu pai estava assustado, avisou minha mãe que levaria o desenho a um psiquiatra.
            </div>
          </div>
          <img class='col-sm-3 img' src='../assets/doutor_rindo.png' alt="doutor rindo">

          <div class="row" style="margin:40px">
            <div class='col-sm-3 main-text' style="margin:auto">
              Meu Pai disse a minha mãe que o médico caiu na gargalhada
            </div>
            
            <div class='col-sm-3 main-text' style="margin:auto">
              ... e que o desenho era muito engraçado, era só observar as botas do monstro....
            </div>
            
          </div>
          <img class='col-sm-3 img'  src='../assets/desenho.png' alt="meu desenho">

          <div class="row" style="margin:40px">
            <div class='col-sm-12 main-text' style="margin:auto">
              No dia seguinte, meu pai me devolveu o lápis de cor e papel e disse que poderia desenhar à vontade. Somente uns anos adiante meu pai me contara que tinha levado meus desenhos a um médico psiquiatra porque pensava que era perturbado. Por um lado..., ele não estava errado.
              Hoje em dia meu pai é um dos maiores apoiadores da minha arte. Faço vários desenhos que ele pede e juntos rachamos o bico.
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
            active: false,
        isActiveMerece: false,
        adocelencia: false,
        entrevista: false,
        dispensa: false,
      };
    },    
    methods: {
        setClass(){
            this.active = !this.active
            console.log(this.active)
        },
        getClass(){
            if(!this.active){
                return {
                    'displayN': 'none'
                }
            }else{
                return {
                    '': ''
                }
            }
        },
      toggle(i) {
        if( i == 1 ){
          this.isActiveMerece = !this.isActiveMerece
          this.adocelencia = false
          this.entrevista = false
          this.dispensa = false
        }
        if( i == 2 ){
          this.adocelencia = !this.adocelencia
          this.isActiveMerece = false
          this.entrevista = false
          this.dispensa = false
        }
        if( i == 3 ){
          this.entrevista = !this.entrevista
          this.isActiveMerece = false
          this.adocelencia = false
          this.dispensa = false
        }
        if( i == 4 ){
          this.dispensa = !this.dispensa
          this.isActiveMerece = false
          this.adocelencia = false
          this.entrevista = false
        }
        
      },
    },
    name: 'Home-',
  }
</script>
<style>
.hidden{
    display: none;
}
  .historias{
    text-align:left; 
    margin-left: 20px; 
    margin-top:20px;
  }
.img{
  height: 5%;
}
.titulo-principal{
    color: #000;
    font-size: 10vh; 
    font-weight: 700;
  }

  .main-text{
    color: #000;
    font-size: 3vh;
    font-weight: 700;
    margin: auto;
  }
  .bakcground-img{    
      filter: blur(5px);
  }
  .home{
    width: 100%;
  }
  .panel{
      overflow-y: scroll;
      z-index: 999999;
      height: 80vh;
      max-height: 100vh;
      width: 100vw;
  }
  .hidden{
      display: none;
  }
  .panel-draw{
      /* opacity: 0.9; */
      /* background-color: gray; */
      height: 80vh;
      width: 95vw;

      position: absolute;
      top:0;
      bottom: 0;
      left: 0;
      right: 0;    
      margin: auto;
  }
  .img{
      max-height: 80vh;
      max-width: 90vw;
  }
  .blur {
      background: rgba(255, 255, 255, 0.2); 
      backdrop-filter: blur(8px); 
      height: 100vh;
      width: 100%;
  }
  .form-edit{
      
      margin: auto;
      width: 50%;
      padding: 10px;
  }
  
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;   
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
</style>