import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import {FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {library} from '@fortawesome/fontawesome-svg-core'
import { faPager,faList, faColumns,faPhone, faHome, faPerson, faHeadSideVirus } from '@fortawesome/free-solid-svg-icons'

import FutureSlider from 'vue-future-slider'

import 'vue-future-slider/dist/vue-future-slider.css'
import VueEasyLightbox from 'vue-easy-lightbox'

library.add(faPager,faList,faColumns,faPhone, faHome, faPerson,faHeadSideVirus);

createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.use(store)
.use(router)
.use(FutureSlider)
.use(VueAxios, axios)
.use(moment)
.use(VueEasyLightbox)

.mount('#app')
