<template>

  <div class="home background">    
    <div class="logo2">
      <img style='width: 30%;' src="@/assets/Logo2.png" alt="logo"/>
    </div>
    <div style="color:transparent">
      Me chamo Diego. Faço Caricaturas, charges e desenhos em geral. Também faço desenhos de pets. Realizo a arte à lapis preto e branco, à caneta, lápis de cor e digital. Merece 1 desenho. Merece1desenho. 
    </div>
    <div class="row end">
      <div class="col row">
        <router-link
          class="end-ref col-md-9"
            :to="{
              name: 'draw',
              params: {
                id: '999999'
              }
            }">°Explorar</router-link>
          <div class="col-md-3">
            <div class = 'row'>
              <a href='https://www.instagram.com/diego.sebastian.gomez?utm_source=ig_web_button_share_sheet&igsh=OGQ5ZDc2ODk2ZA==' target="_blank">                 
                <img class=' img-insta-p' src='../assets/insta.png' alt="insta">
                <a>@Merece1Desenho</a>
              </a>  

            </div>
          </div>
      </div>
      <router-link to="/contact" class="end-ref">°Contato</router-link>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    data() {
      return {
        img: 'https://wallpaperaccess.com/full/2918043.jpg',
        items:[]
      };
    },
    async mounted(){
        this.callApi()
    },
    meta: {
      title: 'Welcome to the dashboard'
    },
    name: 'Home-',
    components: {
    },
    methods:{     
        getClass(type){
            if(type == 'track'){
                if(this.checked){
                    return {'toggle-track-check':'toggle-track-check'}
                }
                return {'toggle-track':'toggle-track'}
            }else{
                if(this.checked){
                    return {'toggle-handle-check':'toggle-handle-check'}
                }
                return {'toggle-handle':'toggle-handle'}
            }

        },
      format_date(value){
        if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
        }
      },   
      callApi(){
        //   this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/getDraw',{
        //   headers: {
        //       'Content-Type': 'application/json',
        //       //  'Cross-Origin-Read-Blocking': '*'
        //   },            
        // }).then((response) => {
        //     if(response.status == 200 && response.data.length > 0){
        //         response.data.forEach(element => {
        //             var data = {}
        //             data.date = this.format_date(element.created_at)
        //             data.description = element.description
        //             data.title = element.title
        //             data.file = element.file
        //             data.id = element.id
        //             this.items.push(data)
        //         });
        //     }
        // },)
      },
    }
  }
</script>
<style>
.logo2{
  padding-top: 5%;
}

@media screen and (max-width: 600px) {
  body {
    background-image: url('../assets/background.png');
  }
}
@media screen and (min-width: 601px) {
  body {
    background-image: url('../assets/background.png');
    background-size: 100% 100%;
  }
}
.background {
  /* background-image: url('../assets/background.png');
  width: 100%;
  height: auto; */
/* 
  background-size: 100% 100%;
  background-position: bottom; */
  /* background-repeat: repeat;
  background-size: contain; */
  /* height: 100vh;
  width: 100%; */
}
  /* body {
      background-image: url('../assets/background.png');
    } */
  .home{
    height: 100vh;
    width: 100%;
  }
  .img-insta-p{
    width: 5%;
  }
  .info-insta{
    color: #000;
    font-size: 2vh;
    font-weight: 700;
  }
</style>