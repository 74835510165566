<template>
    <div>
        <div class="form">
        <h2>Novo</h2>
            <form>
                <div >
                    <div class="form-group">
                    </div>
                    <table class='table'>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Arquivo</th>
                                <th scope="col">Titulo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="i in qtdItens" v-bind:key="i">
                                <td> 
                                    <input v-if="test[i-1] == undefined || test[i-1]['file64'] == undefined" type="file" 
                                            @change="onFileChanged($event, i)"
                                            accept="image/*"
                                            capture id="file">
                                    <button v-else  @click.prevent="removeFile(i)">Alterar</button>

                                    <input type="hidden" id="file64" v-model="file64"> 
                                </td>
                                <td> {{ test[i-1] != undefined ? test[i-1]['name'] : '' }}</td>
                                <td>    
                                    <input v-on:change="setDescription(i,$event.target.value)" type="description" v-bind:id="'description'+i" required /> 
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="form-group">
                        <label >Categoria:</label><br/>
                        <div  v-for="item in items"  :key="item.id">
                            <input type="checkbox" :id=item.id :name=item.title :value="item.id " v-model="checked" ><label> {{item.title}} </label>
                        </div>
                            
                    </div>
                
                    <button type="submit" @click.prevent="submitForm">Salvar</button>
                </div>
            </form>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
            <div style="height: max-content;"> &nbsp; </div>
        </div>
    </div>
  </template>
  
  <script>
    export default {
        data() {
            return {
                qtdItens:1,
                checked:[],
                items:[],
                stories: '',
                title: '',
                description: [],
                filePath:'',
                file64:'',
                fileName:'',
                category:'',
                test:[],
                id_stories: 0
            }
        },
        mounted(){
            this.getCategory()
        },
                
        methods: {
            removeFile(i){
                console.log(i)
                console.log(this.test)
                this.test.splice(i - 1)
                console.log(this.test)
            },
            setDescription(i, v){
                this.description[i] = v
                console.log(this.description)
            },
            getCategory(){
                console.log('a');
                this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/getTags', {
                    headers: {
                        'Content-Type': 'application/json',
                        //  'Cross-Origin-Read-Blocking': '*'
                    },            
                }).then((response) => {
                    response.data.forEach(element => {
                        var data = {}
                        data.title = element.title
                        data.id = element.id
                        this.items.push(data)
                    });
                },)

            },
            async submitForm()  {
                console.log(this.title)
                if(this.stories){
                    var payload = {}
                    payload.description = this.title
                    payload.titulo = this.title
                    await this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/setNewStories', payload,{
                        headers: {
                            'Content-Type': 'application/json',
                            //  'Cross-Origin-Read-Blocking': '*'
                        },            
                    }).then((response) => {
                        if(response.status == 201){
                            this.id_stories = response.data['id']
                        }
                    },)
                }
                this.test.forEach(element => {
                var payload = {}
                    payload.title = this.description[element['index']]
                    payload.description = payload.title
                    payload.file = element['file64']
                    payload.fileName = element['name']
                    payload.category = this.checked.join(',')
                    payload.stories = this.id_stories
                    payload.seq = element['index']
                    console.log(payload)
                    this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/setDraw', payload,{
                        headers: {
                            'Content-Type': 'application/json',
                            //  'Cross-Origin-Read-Blocking': '*'
                        },            
                    }).then((response) => {
                        if(response.status == 201){
                            this.$router.push('/draw-list')
                        }
                    },)
                    });

                // { title: this.title, description: this.description, file: this.file64, fileName: this.fileName, category: this.checked.join(','), seq: seq}

            },
            async onFileChanged(e,i){
                const file = File;
                const target = e.target ;
                var name = [];
                name['index'] = i
                if(target.files.length > 0){
                    file.value = target.files[0];
                    // this.test['fileName'] = target.files[0].name
                    name['name'] = target.files[0].name
                    // this.fileName = target.files[0].name
                }

                const f = document.querySelector('input[type=file]').files[0]
                const reader = new FileReader()

                let rawImg;
                reader.onloadend = () => {
                    rawImg = reader.result;
                    this.file64 = rawImg;
                    this.test['file64'] = rawImg
                    name['file64'] = rawImg
                }
                await reader.readAsDataURL(f);
                // this.file64 = f.value;
                this.test.push(name)
                console.log(this.test)
                this.test.length - ( this.qtdItens  ) == 0 ?( this.qtdItens ++) : 0
            }
        }
    }
  </script>
  
  <style>
    .form {
        min-height: 100%;
        
        background-color: antiquewhite;
        /* position: relative; */
        /* width: 100%;
        margin: 0 auto;
        margin-top: 10%;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px; */
    }
    
    h2 {
        text-align: center;
        margin-bottom: 20px;
    }
    
    .form-group {
        margin-bottom: 10px;
    }
    
    label {
        display: block;
        margin-bottom: 5px;
    }
    
    input[type="title"],
    input[type="description"],
    input[type="file"]{
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
    
    button[type="submit"] {
        display: block;
        width: 100%;
        padding: 10px;
        margin-top: 10px;
        background-color: #4caf50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
    
    button[type="submit"]:hover {
        background-color: #3e8e41;
    }
  </style>