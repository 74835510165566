<template>
    <div class="home background ">    
        <div class="panel blur">
            <form class='form-edit'>
                <div>
                    <div class="form-group">
                    Titulo:<br/>
                        <input type="title" id="title" v-model="title" required />
                    </div>
                
                    <div class="form-group">
                        Descrição:<br/>
                        <input type="description" id="description" v-model="description" required />
                    </div>   
                    <div class="form-group">
                        <label >Categoria:</label><br/>
                        <div  v-for="item in items"  :key="item.id">
                            <input type="checkbox" :id=item.id :name=item.title :value="item.id " v-model="checked" ><label> {{item.title}} </label>
                        </div>
                            
                    </div>
                
                    <button type="submit" @click.prevent="submitForm">Salvar</button>
                </div>
            </form>
        </div>
    </div>
  </template>
  
  <script>
  import moment from 'moment'

  

  
    export default {
      data() {
        return {
            data:[],
            checked:[],
            items:[],
            tags:[],
            title: '',
            description: '',
            filePath:'',
            file64:'',
            fileName:'',
            category:''
        };
      },
      async mounted(){
        this.callApi()
      },
      name: 'Home-',
      components: {
      },
      methods:{
            // getCategoryFromDraw(){
            //     console.log('a');
            //     this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/getTagsFromDraw', 
            //     { id: this.$route.params.id,}
            //     ,{
            //         headers: {
            //             'Content-Type': 'application/json',
            //             //  'Cross-Origin-Read-Blocking': '*'
            //         },            
            //     }).then((response) => {
            //         response.data.forEach(element => {
            //             var data = {}
            //             data.title = element.title
            //             data.id = element.id
            //             this.tags.push(data)
            //         });
            //     },)

            // },
            // getCategory(){
            //     console.log('a');
            //     this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/getTags', {
            //         headers: {
            //             'Content-Type': 'application/json',
            //             //  'Cross-Origin-Read-Blocking': '*'
            //         },            
            //     }).then((response) => {
            //         this.getCategoryFromDraw()
            //         response.data.forEach(element => {
            //             var data = {}
            //             data.title = element.title
            //             data.id = element.id
            //             this.items.push(data)
            //         });
            //     },)

            // },
            format_date(value){
                if (value) {
                return moment(String(value)).format('DD/MM/YYYY')
                }
            },
            submitForm() {
                console.log(this.checked.join(','))
                this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/setDraw',
                 { title: this.title, description: this.description, id:this.$route.params.id, new:'false' },{
                    headers: {
                        'Content-Type': 'application/json',
                        //  'Cross-Origin-Read-Blocking': '*'
                    },            
                }).then((response) => {
                    if(response.status == 201){
                        this.$router.push('/draw-list')
                    }
                },)
            },
            callApi(){
                console.log( this.$route.params.id)
                this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/getDraw',
                { id: this.$route.params.id,},
                {
                headers: {
                    'Content-Type': 'application/json',
                    //  'Cross-Origin-Read-Blocking': '*'
                },            
            }).then((response) => {
                if(response.status == 200 && response.data.length > 0){
                    this.data = response.data
                    this.title = response.data[0]['title']
                    this.description = response.data[0]['description']
                    // this.getCategory()
                }
            },)
            },  
      }
    }
  </script>
  <style>
    .bakcground-img{    
        filter: blur(5px);
    }
    .home{
      width: 100%;
    }
    .panel{
        z-index: 999999;
        height: 80vh;
        width: 100vw;
    }
    .hidden{
        display: none;
    }
    .titulo{
        font-size: 30px;
        color: black;
        font-weight: 700;
    }
    .descricao{
        font-size: 25px;
        color: black;
        font-weight: 500;
    }
    .panel-draw{
        /* opacity: 0.9; */
        /* background-color: gray; */
        height: 80vh;
        width: 95vw;

        position: absolute;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;    
        margin: auto;
    }
    .img{
        max-height: 80vh;
        max-width: 90vw;
    }
    .blur {
        background: rgba(255, 255, 255, 0.2); 
        backdrop-filter: blur(8px); 
        height: 100vh;
        width: 100%;
    }
    .form-edit{
        
        margin: auto;
        width: 50%;
        padding: 10px;
    }
  </style>