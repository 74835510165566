<template>
    <div>
        <h2>Lista de Tags</h2>
        <div class="container">
            <div class="row">
                <div class="col-sm-1"><h5 class="new float-left"><router-link to="/new-tag"><button type="button" class="btn btn-lg btn-primary" >Novo</button></router-link></h5></div>
                <div class="col-sm-10"></div>
            </div>
        </div>
        
        <table  class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Titulo</th>
                    <th scope="col">Descrição</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items" :key="item.id">
                    <th scope="row"> <input class="form-check-input" type="checkbox" value=""  v-bind:id="item.id" @click="selectRow(item.id)"></th>
                    <td> {{item.title}} </td>
                    <td> {{item.description}} </td>
                </tr>
            </tbody>
        </table>
    </div>
  </template>
  
  <script>
    import moment from 'moment'

    export default {
        data() {
            return {
                selectedIds:[],
                items: []
            };
        },
        components: {
        },
        async mounted(){
            this.callApi()
        },
        methods: { 
            callApi(){
                this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/getTags',{
                headers: {
                    'Content-Type': 'application/json',
                    //  'Cross-Origin-Read-Blocking': '*'
                },            
            }).then((response) => {
                if(response.status == 200 && response.data.length > 0){
                    response.data.forEach(element => {
                        console.log(element)
                        var data = {}
                        // data.date = this.format_date(element.created_at)
                        data.description = element.description
                        data.title = element.title
                        // data.file = element.file
                        // data.id = element.id
                        this.items.push(data)
                    });
                }
            },)
            },
            selectRow(id){
                this.selectedIds.push(id);
                console.log(this.selectedIds)
            },
            format_date(value){
                if (value) {
                return moment(String(value)).format('DD/MM/YYYY')
                }
            },
        },
    };
  </script>
  
  <style>
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  </style>