
<template >
  <div class="general">
    <!-- <MainMenu /> -->
    <router-view/>
  </div>
</template>
<script>
  import moment from 'moment'
  // import MainMenu from './components/MainMenu.vue'
  export default {
    name: 'Merece1Desenho',
    mounted() {
      document.title = "Merece1Desenho";
    },
    title: '',    
    metaInfo: {
      title: 'Default Title',
    },
    data() {
      return {}
    },
    components: {
      // MainMenu,
    },
    methods:{      
      format_date(value){
          if (value) {
            return moment(String(value)).format('DD/MM/YYYY')
          }
      },
    }
    
  }
  

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.end {
  position: fixed;
  bottom: 0;
  opacity: 0.5;
  background-color: white;
  width: 100%;
  text-align: start;
  color: #000;
  font-size: 30px;
}
.end-ref {
    text-decoration: none;
  text-align: start;
  color: #000;
  font-size: 30px;
  font-weight: 700;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

  
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;   
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
</style>
