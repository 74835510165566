<template>
    <div class="form">
      <h2>Nova tag</h2>
        <form>
            <div >
                <div class="form-group">
                   Titulo:<br/>
                    <input type="title" id="title" v-model="title" required />
                </div>
            
                <div class="form-group">
                    <label >Descrição:</label>
                    <input type="description" id="description" v-model="description" required />
                </div>
                <div class="form-group">
                    <label >Tipo:</label><br/>
                    <select v-model="type" >+
                        <option v-for="item in items" :value="item" :key="item.id">{{item.type}}</option>                        
                    </select>
                </div>
            
                <button type="submit" @click.prevent="submitForm">Salvar</button>
            </div>
        </form>
    </div>
  </template>
  
  <script>
    export default {
        data() {
            return {
                items:[],
                title: '',
                description: '',
                type: ''
            }
        },
        mounted(){
            this.getCategory()
        },
                
        methods: {
            getCategory(){
                console.log('a');
                this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/getTagType', {
                    headers: {
                        'Content-Type': 'application/json',
                        //  'Cross-Origin-Read-Blocking': '*'
                    },            
                }).then((response) => {
                    response.data.forEach(element => {
                        var data = {}
                        data.type = element.type
                        data.id = element.id
                        this.items.push(data)
                        console.log(this.items)
                    });
                },)

            },
            submitForm() {
                
                this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/setTags', { title: this.title, description: this.description ,  type: this.type.id},{
                    headers: {
                        'Content-Type': 'application/json',
                        //  'Cross-Origin-Read-Blocking': '*'
                    },            
                }).then((response) => {
                    if(response.status == 201){
                        this.$router.push('/draw-list')
                    }
                },)
            },
            async onFileChanged(e){
                const file = File;
                const target = e.target ;
                if(target.files.length > 0){
                    file.value = target.files[0];
                    this.fileName = target.files[0].name
                }

                const f = document.querySelector('input[type=file]').files[0]
                const reader = new FileReader()

                let rawImg;
                reader.onloadend = () => {
                    rawImg = reader.result;
                    console.log('rawImg');
                    console.log(rawImg);
                    this.file64 = rawImg;
                }
                await reader.readAsDataURL(f);
                // this.file64 = f.value;
            }
        }
    }
  </script>
  
  <style>
    .form-tag {
        /* position: relative;
        margin: 0 auto;
        margin-top: 10%;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px; */
    }
    
    h2 {
        text-align: center;
        margin-bottom: 20px;
    }
    
    .form-group {
        margin-bottom: 10px;
    }
    
    label {
        display: block;
        margin-bottom: 5px;
    }
    
    input[type="title"],
    input[type="description"],
    input[type="file"]{
        /* width: 80%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px; */
    }
    
    button[type="submit"] {
        display: block;
        width: 100%;
        padding: 10px;
        margin-top: 10px;
        background-color: #4caf50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
    
    button[type="submit"]:hover {
        background-color: #3e8e41;
    }
  </style>