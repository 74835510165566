<template>
  <div class="home background ">    
      <div class="panel blur">

        <div class="texts">Categorias</div>
        
          <div class="tags" style=''>
          <div class="row">
            
            <ul id="navmenu">
              <li ><a style="display: none;">Samples</a>
                <ul class="subsites">
                  <li class="end-ref"  v-on:click="setActive()" ><a >° Charges ></a>
                      <ul v-bind:class="getClass()">
                        <li>
                          <router-link
                            class="end-ref"
                              :to="{
                                name: 'draw',
                                params: {
                                  id: '1'
                                }
                              }">
                              <label> Alienígenas do Passado</label>
                        </router-link>
                          </li>
                        <li>
                          <router-link
                            class="end-ref"
                              :to="{
                                name: 'draw',
                                params: {
                                  id: '2'
                                }
                              }">
                              <label> Oak Island</label>
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            class="end-ref"
                              :to="{
                                name: 'draw',
                                params: {
                                  id: '3'
                                }
                              }">
                              <label> Histórias da fazenda</label>
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            class="end-ref"
                              :to="{
                                name: 'draw',
                                params: {
                                  id: '4'
                                }
                              }">
                              <label> Histórias do trabalho</label>
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            class="end-ref"
                              :to="{
                                name: 'draw',
                                params: {
                                    id: '5'
                                }
                              }">
                              <label> Diversos</label>
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            class="end-ref"
                              :to="{
                                name: 'draw',
                                params: {
                                    id: '6'
                                }
                              }">
                              <label> Celebridades</label>
                          </router-link>
                        </li>
                      </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

            <div class="row"><router-link to="/about" class="end-ref">° Como nasceu Merece1Desenho</router-link></div>
            <div class="row"><router-link to="/contact" class="end-ref">° Fale conosco</router-link></div>
          </div>
        </div> 
    </div>
</template>
  
  <script>
    import moment from 'moment'
    export default {
      data() {
        return {
          active: false,
          selectedIds:[],
          items: [],
          open: false,
        };
      },
      components: {},
        async mounted(){
          this.callApi()
        },
        methods: { 
          setActive(){
            this.active = !this.active
          },
          getClass(){
            if(this.active){
              return {'display':'block'};
            }else{
              return {'no-display': 'none'};
            }
          },
          onlyUnique(value, index, array) {
            return array.indexOf(value) === index;
          },  
          callApi(){
            this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/getExplores',{
              headers: {
                'Content-Type': 'application/json',
              },            
            }).then((response) => {
                    if(response.status == 200 && response.data.length > 0){
                      console.log(response.data)
                      response.data.forEach(element => {
                        var exploded = element['tag_title'].split('->');
                        for(var i =0; i< exploded.length; i++){
                          if( this.items[i] == undefined){
                            this.items[i] = []
                          }
                          this.items[i].push(exploded[i]);
                        }
                      })
                      console.log(this.items)
                    }
                },)
            },
            format_date(value){
                if (value) {
                return moment(String(value)).format('DD/MM/YYYY')
                }
            },
        },
    };
  </script>
  <style>


  </style>